import { useJsApiLoader } from "@react-google-maps/api";
import PuffLoader from "react-spinners/PuffLoader";
import { GooglePlaceResult, Listing } from "./@types";
import { Destination } from "./components/Destination";
import { Rating } from "./components/Rating";
import { Map } from "./components/Map";
import { RouteInfoWrapper } from "./components/styled";
import { useEffect, useState } from "react";

const GOOGLE_API_LIBRARIES = ["places"] as any;

const App = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: GOOGLE_API_LIBRARIES,
  });

  const [startPlace, setStartPlace] = useState<GooglePlaceResult>();
  const [endPlace, setEndPlace] = useState<GooglePlaceResult>();
  const [listings, setListings] = useState<Listing[]>([]);

  useEffect(() => {
    const requestURL = process.env.REACT_APP_LISTINGS_API_URL as string;
    fetch(
      `${requestURL}?fetchAll=true&continents=northAmerica&travelingWhileBlackScore[]=0&travelingWhileBlackScore[]=5`
    ).then(async response => {
      const json = await response.json();
      setListings(json);
    });
  }, []);

  if (!isLoaded || loadError) {
    /*TODO make it centered */
    return <PuffLoader size={150} color="#36d7b7" />;
  }

  return (
    <>
      <RouteInfoWrapper>
        <Destination onStartLocationChanged={setStartPlace} onEndLocationChanged={setEndPlace} />
        <Rating />
      </RouteInfoWrapper>
      <Map startPlace={startPlace} endPlace={endPlace} listings={listings} />
    </>
  );
};

export default App;
