import styled from "styled-components";

export const InputWrapper = styled.div`
  position: relative;

  input {
    font-family: var(--base-text-font-type);
    color: var(--base-text-color);
    font-size: 16px;
    padding-left: 45px;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
`;

export const CheckboxWrapper = styled.div`
  width: 50px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding: 14px 0;
  background-color: rgba(0, 47, 0, 0.06);
`;

export const FilterNameWrapper = styled.div`
  font-weight: 600;
`;

export const CheckboxInfoWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 6px 0;

  &:first-of-type {
    margin-top: 35px;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked + .checkmark::before {
    content: "✓";
    color: var(--base-text-color);
    font-size: 28px;
    font-weight: 600;
    line-height: 20px;
    padding-bottom: 7px;
    padding-left: 5px;
  }
`;

export const Checkmark = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid green;
  border-radius: 4px;
  cursor: pointer;
`;

export const RouteInfoWrapper = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 45px;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const DestinationWrapper = styled.div`
  margin-right: 100px;

  @media (max-width: 1024px) {
    margin: 0 auto;
  }

  input {
    width: 362px;
    height: 52px;
    display: block;
    margin-bottom: 10px;
    border: none;
    border-radius: 33px;
    background-color: var(--white);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  input:focus {
    outline: none;
  }
`;

export const RatingWrapper = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    margin-top: 45px;
  }
`;

export const RatingTextWrapper = styled.div`
  width: 322px;
  height: 122px;
  margin-right: 20px;
  padding: 20px;
  background: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
`;

export const RatingInfoWrapper = styled.div`
  height: 21px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
`;

export const RatingInfoTextWrapper = styled.span`
  margin-left: 7px;
`;

export const MapWrapper = styled.div`
  width: 60%;
  height: 468px;
  margin: 60px auto;
  border-radius: var(--border-radius);
  overflow: hidden;

  & > div {
    height: 468px;
  }

  @media (max-width: 1024px) {
    width: 70%;
  }
`;

export const InfoWindowWrapper = styled.div`
  width: 324px;
`;

export const InfoWindowImg = styled.img`
  width: 100%;
  height: 125px;
`;

export const InfoWindowTextWrapper = styled.div`
  display: flex;
  position: relative;
  padding: 12px;
  font-size: 18px;
`;

export const InfoWindowTWBWrapper = styled.div`
  font-size: 12px;
  position: absolute;
  right: 12px;
`;

export const InfoWindowBtn = styled.button`
  width: 104px;
  height: 28px;
  border-radius: 30px;
  border: 1px solid #009400;
  color: #009400;
  background-color: var(--white);
  font-weight: 700;
  font-size: 12px;
`;

export const StarRatingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 12px 12px;
`;
