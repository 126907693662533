import { CheckboxInfoWrapper, CheckboxWrapper, Checkmark, FilterNameWrapper } from "./styled";

export const Checkbox = () => {
  return (
    <CheckboxWrapper>
      <FilterNameWrapper>Filter</FilterNameWrapper>
      <CheckboxInfoWrapper>
        <input type="checkbox" />
        <Checkmark />
      </CheckboxInfoWrapper>
      <CheckboxInfoWrapper>
        <input type="checkbox" />
        <Checkmark />
      </CheckboxInfoWrapper>
      <CheckboxInfoWrapper>
        <input type="checkbox" />
        <Checkmark />
      </CheckboxInfoWrapper>
    </CheckboxWrapper>
  );
};
