import { RatingInfoTextWrapper, RatingInfoWrapper } from "./styled";
import { RatingTextProps } from "./types";

export const RatingText = ({ icon, text }: RatingTextProps) => {
  return (
    <RatingInfoWrapper>
      {icon}
      <RatingInfoTextWrapper>{text}</RatingInfoTextWrapper>
    </RatingInfoWrapper>
  );
};
