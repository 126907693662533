import { InfoTextProps } from "./types";
import { PinIcon } from "./PinIcon";
import { IconWrapper, InputWrapper } from "./styled";

export const InputWithIcon = ({ type, placeholder }: InfoTextProps) => {
  return (
    <InputWrapper>
      <input type={type} placeholder={placeholder} />
      <IconWrapper>
        <PinIcon color="#053E2F" width="22px" height="22px" />
      </IconWrapper>
    </InputWrapper>
  );
};
