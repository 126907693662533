import { MutableRefObject, useRef } from "react";
import { GooglePlaceResult, GoogleSearchBox } from "../@types";
import { InputWithIcon } from "./InputWithIcon";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { DestinationWrapper } from "./styled";

export type DestinationProps = {
  onStartLocationChanged: (place: GooglePlaceResult) => void;
  onEndLocationChanged: (place: GooglePlaceResult) => void;
};

export const Destination = ({ onStartLocationChanged, onEndLocationChanged }: DestinationProps) => {
  const fromLocationSearchRef = useRef<GoogleSearchBox | null>(null);
  const endLocationSearchRef = useRef<GoogleSearchBox | null>(null);

  function handlePlaceChanged(
    ref: MutableRefObject<GoogleSearchBox | null>,
    cb: (place: GooglePlaceResult) => void
  ) {
    return () => {
      const [place] = ref.current!.getPlaces() as GooglePlaceResult[];
      if (place) {
        cb(place);
      }
    };
  }

  return (
    <DestinationWrapper>
      <StandaloneSearchBox
        onLoad={(ref: GoogleSearchBox) => (fromLocationSearchRef.current = ref)}
        onPlacesChanged={handlePlaceChanged(fromLocationSearchRef, onStartLocationChanged)}
      >
        <InputWithIcon type="text" placeholder="Enter a from location" />
      </StandaloneSearchBox>
      <StandaloneSearchBox
        onLoad={(ref: GoogleSearchBox) => (endLocationSearchRef.current = ref)}
        onPlacesChanged={handlePlaceChanged(endLocationSearchRef, onEndLocationChanged)}
      >
        <InputWithIcon type="text" placeholder="Enter a to location" />
      </StandaloneSearchBox>
    </DestinationWrapper>
  );
};
