import { RatingText } from "./RatingText";
import { PinIcon } from "./PinIcon";
import { Checkbox } from "./Checkbox";
import { RatingTextWrapper, RatingWrapper } from "./styled";

export const Rating = () => {
  return (
    <RatingWrapper>
      <RatingTextWrapper>
        <RatingText
          icon={<PinIcon color="#009400" circleColor="#000" />}
          text="Traveling While Black Score (4.5-5)"
        />
        <RatingText
          icon={<PinIcon color="#F2D469" circleColor="#000" />}
          text="Traveling While Black Score: 4.0-4.49"
        />
        <RatingText
          icon={<PinIcon color="#FF4D00" circleColor="#000" />}
          text="Traveling While Black Score < 4.0"
        />
        <RatingText icon={<PinIcon />} text="Start and End Destinations" />
      </RatingTextWrapper>
      <Checkbox />
    </RatingWrapper>
  );
};
