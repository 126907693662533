import { useMemo, useState } from "react";
import { renderToString } from "react-dom/server";
import { isEqual } from "lodash-es";
import { Listing } from "../@types";
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { PinIcon, SIZE_FOR_MAP } from "./PinIcon";
import {
  InfoWindowBtn,
  InfoWindowImg,
  InfoWindowTWBWrapper,
  InfoWindowTextWrapper,
  InfoWindowWrapper,
  MapWrapper,
  StarRatingWrapper,
} from "./styled";
import StarRatings from "react-star-ratings";
import { ListingInfoWindowProps, Location, MapProps } from "./types";

const createMapMarkerIcon = (iconProps: any = {}) => {
  return {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(renderToString(<PinIcon {...SIZE_FOR_MAP} {...iconProps} />)),
  };
};

const WAYPOINT_MARKER_ICON = createMapMarkerIcon();
const RED_MARKER_ICON = createMapMarkerIcon({ color: "#FF4D00" });
const GREEN_MARKER_ICON = createMapMarkerIcon({ color: "#009400" });
const YELLOW_MARKER_ICON = createMapMarkerIcon({ color: "#F2D469" });

const DEFAULT_MAP_PROPS = {
  zoom: 4,
  center: {
    lat: 39.8097343,
    lng: -98.5556199,
  },
};

const SHOW_ALL_MARKERS_INITIALLY = true; // this is supposed to be a setting, so far we need to show all

const selectIconFromRating = (listing: Listing) => {
  const rating = listing.average_rating.traveling_while_black_rating;

  if (rating >= 4.5) {
    return GREEN_MARKER_ICON;
  } else if (rating > 4) {
    return YELLOW_MARKER_ICON;
  }

  return RED_MARKER_ICON;
};

function arePointsNear(checkPoint: Location, centerPoint: Location, km = 20) {
  var ky = 40000 / 360;
  var kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
  var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
  var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
  return Math.sqrt(dx * dx + dy * dy) <= km;
}

const ListingInfoWindow = ({ listing, onCloseClick }: ListingInfoWindowProps) => {
  return (
    <InfoWindow position={listing} onCloseClick={onCloseClick}>
      <InfoWindowWrapper>
        <InfoWindowImg src={listing.image} />
        <InfoWindowTextWrapper>
          <div>{listing.post_title}</div>
          <InfoWindowTWBWrapper>Traveling While Black</InfoWindowTWBWrapper>
        </InfoWindowTextWrapper>
        <StarRatingWrapper>
          <InfoWindowBtn>Share Review</InfoWindowBtn>
          <div className="star-ratings">
            <StarRatings
              rating={+listing.average_rating.traveling_while_black_rating}
              starDimension={"15px"}
              starSpacing={"1px"}
              starEmptyColor={"#fff"}
              starRatedColor={"#FFBB0C"}
            />
          </div>
        </StarRatingWrapper>
      </InfoWindowWrapper>
    </InfoWindow>
  );
};

export const Map = ({ startPlace, endPlace, listings }: MapProps) => {
  const origin = startPlace?.geometry?.location!;
  const destination = endPlace?.geometry?.location!;
  const [directionServiceResults, setDirectionServiceResults] =
    useState<google.maps.DirectionsResult | null>();
  const [activeListing, setActiveListing] = useState<Listing | undefined>();

  const directionServiceOptions = useMemo(() => {
    return {
      destination,
      origin,
      travelMode: google.maps.TravelMode.DRIVING,
    };
  }, [destination, origin]);

  const filteredListings = useMemo(() => {
    const routePath =
      directionServiceResults?.routes[0]?.overview_path.map(path => ({
        lat: path.lat(),
        lng: path.lng(),
      })) || [];

    return listings
      .map(listing => ({
        ...listing,
        lat: parseFloat(listing?.latitude_meta?.value),
        lng: parseFloat(listing?.longitude_meta?.value),
      }))
      .filter(({ lat, lng }) => {
        if (SHOW_ALL_MARKERS_INITIALLY) {
          return true;
        }

        if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
          return routePath.some(rp => arePointsNear({ lat, lng }, rp));
        }

        return false;
      });
  }, [directionServiceResults, listings]);

  const handleMarkerClick = (listing: Listing) => {
    setActiveListing(listing);
  };

  const mapProps = directionServiceResults ? {} : DEFAULT_MAP_PROPS;

  return (
    <MapWrapper>
      <GoogleMap {...mapProps}>
        {origin && destination && (
          <DirectionsService
            options={directionServiceOptions}
            callback={(response: google.maps.DirectionsResult | null | undefined) => {
              // TODO it is week place if Google changes their API
              if (
                !isEqual(response?.geocoded_waypoints, directionServiceResults?.geocoded_waypoints)
              ) {
                setDirectionServiceResults(response);
              }
            }}
          />
        )}
        {directionServiceResults && (
          <DirectionsRenderer
            options={{
              directions: directionServiceResults,
              markerOptions: {
                icon: WAYPOINT_MARKER_ICON,
                zIndex: 1,
              },
            }}
          />
        )}
        {filteredListings.map(listing => {
          return (
            <Marker
              key={listing.ID}
              position={listing}
              icon={selectIconFromRating(listing)}
              zIndex={2}
              onClick={() => handleMarkerClick(listing)}
            />
          );
        })}
        {activeListing && (
          <ListingInfoWindow
            listing={activeListing}
            onCloseClick={() => setActiveListing(undefined)}
          />
        )}
      </GoogleMap>
    </MapWrapper>
  );
};
